<template>
    <div>
        <b-container class="appointment justify-content-center d-flex">
            <b-card class="container-min">

                <div v-if="saving" class="d-flex justify-content-center align-items-center">
                    <b-spinner class="mr-2" variant="primary"></b-spinner>
                    Gerando...
                </div>

                <b-form v-show="!saving" class="form-template" @submit.stop.prevent="onSubmit">
                    <b-alert variant="danger" :show="errorMessage !== ''" v-html="errorMessage"></b-alert>

                    <b-row>
                        <b-col cols="12"
                        >
                            <b-form-group
                                id="input-group-1"
                                label="Número do Contrato:"
                                label-for="input-1"
                                description="Informe o número do contrato no RBX. Caso o contrato seja um pacote, informe o número de qualquer contrato relacionado ao plano do pacote."
                            >
                                <b-form-input
                                    id="inputContractCode"
                                    v-model="form.contractCode"
                                    class="text-center"
                                    :disabled="loading"
                                    :state="contractStateError"
                                    style="font-size: 1.5rem; font-weight: 600"
                                    type="tel"
                                    required
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group
                                label="Modelo de documento:"
                                description="Você pode selecionar mais de um modelo de documento e estes serão incorporados em um único documento."
                            >

                                <div class="text-center mt-3 mb-3" v-if="loading">
                                    <div>
                                        <b-spinner variant="primary"></b-spinner>
                                    </div>
                                    Buscando Contratos...
                                </div>

                                <b-form-checkbox
                                    v-for="d in docs"
                                    :key="d.id"
                                    :value="d.id"
                                    name="documentKey[]"
                                    v-model="form.documentKey"
                                >
                                    <div class="font-weight-bolder">{{ d.Nome.toString().replace("- EXT","") }}</div>
                                    <div style="font-size: small" class="text-muted pb-3">
                                        {{ d.Descricao }}
                                    </div>
                                </b-form-checkbox>
                            </b-form-group>
                        </b-col
                        >
                    </b-row>

                    <div class="pl-5 pr-5">
                        <b-button type="submit" pill block :disabled="!hasData" variant="success">
                            Gerar
                        </b-button>
                    </div>
                </b-form>
            </b-card>
        </b-container>
    </div>
</template>
<script>

    import {tryError} from "../../libs"

    export default {
        name: "NewDocument",
        data: () => ({
            contractStateError: null,
            loading: false,
            saving: false,
            errorMessage: "",
            form: {
                contractCode: "",
                documentKey: [],
            },
            docs: [],
        }),
        watch: {
          $route() {
              this.requestData()
          }
        },
        computed: {
            hasData() {
                return this.form.contractCode !== "" && this.form.documentKey.length > 0;
            },
        },
        methods: {
            resetError() {
                this.errorMessage = ''
                this.contractStateError = null
            },
            setItemLocal(){
                let str = localStorage.getItem("print");
                if (str === null || str === '') str = "[]";
                let data = JSON.parse(str)
                if (!data.includes(this.form.contractCode)) {
                    data.push(this.form.contractCode.toString())
                    localStorage.setItem("print",JSON.stringify(data))
                }
            },
            async onSubmit() {
                this.saving = true
                this.resetError()
                try {
                    const {data} = await this.$http.post('/document/generate', this.form)
                    this.setItemLocal()
                    console.log(data)
                    await this.$router.push({
                        path: '/documento',
                        query: {
                            doc: data.link
                        }
                    })
                } catch (err) {
                    const ev = tryError(err);
                    if (ev.code === 401) this.contractStateError = false;
                    this.errorMessage = ev.data.errorMessage
                } finally {
                    this.saving = false
                }


            },
            async requestData(){
                this.loading = true
                try {
                    //Busca os modelos de documentos
                    //let localData = sessionStorage.getItem("data");
                    //if (localData){
                        //this.docs = JSON.parse(localData)
                    //}else {
                        const {data} = await this.$http.get("/document/list_all");
                        this.docs = data;
                        sessionStorage.setItem("data", JSON.stringify(data));
                    //}

                } catch (err) {
                    this.errorMessage = tryError(err).data.errorMessage || 'Falha'
                } finally {
                    this.loading = false
                }
            }
        },
        mounted() {
            this.form.contractCode = this.$route.params.code || '';
            this.requestData()
        },
    };
</script>
